import React from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Featuresearies = ({ deviceType }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <>
      <section className="ptb">
        <div className="match-cover01">
          <div className="container ">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="main-hed01">Featured Series</h2>
              <a href="#" className="btn btn-asee d-none d-md-inline-block">
                More Match <i className="fas fa-angle-right" />
              </a>
            </div>
            <Carousel
              responsive={responsive}
              swipeable={false}
              draggable={false}
              ssr={true}
              infinite={true}
              autoPlay={deviceType !== "mobile" ? true : false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all .5s"
              transitionDuration={1500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div className="slider">
                <a href="#" className="items-ciu d-inline-block w-100">
                  <div className="img-oudi">
                    <img alt="sut" src="images/turm.webp" />
                    <span className="btn btn-ofu"> T20 Cup </span>
                  </div>
                  <div className="cup-details">
                    <h5>Cricket World Cup 2023 Logo: ICC</h5>
                    <p>13 Oct 2023 - 02 Dec 2023</p>
                  </div>
                </a>
              </div>
            
              <div className="slider">
                <a href="#" className="items-ciu d-inline-block w-100">
                  <div className="img-oudi">
                    <img alt="sut" src="images/tru2.png" />
                    <span className="btn btn-ofu"> ODI </span>
                  </div>
                  <div className="cup-details">
                    <h5>Aisa Cup 2023 Logo: ICC</h5>
                    <p>13 Oct 2023 - 02 Dec 2023</p>
                  </div>
                </a>
              </div>
              <div className="slider">
                {" "}
                <a href="#" className="items-ciu d-inline-block w-100">
                  <div className="img-oudi">
                    <img alt="sut" src="images/turm2.webp" />
                    <span className="btn btn-ofu"> ODI </span>
                  </div>
                  <div className="cup-details">
                    <h5>ENGLAND Men Tour 2023: ICC</h5>
                    <p>13 Oct 2023 - 02 Dec 2023</p>
                  </div>
                </a>
              </div>
              <div className="slider">
                <a href="#" className="items-ciu d-inline-block w-100">
                  <div className="img-oudi">
                    <img alt="sut" src="images/tru3.png" />
                    <span className="btn btn-ofu"> ODI </span>
                  </div>
                  <div className="cup-details">
                    <h5>European Cricket League T10</h5>
                    <p>13 Oct 2023 - 02 Dec 2023</p>
                  </div>
                </a>
              </div>
              
              {/* <div>Item 4</div> */}
            </Carousel>
            {/* <a href="#" className="btn btn-asee d-table d-md-none mx-auto mt-4">
            See All <i className="fas fa-angle-right" />
          </a> */}
          </div>
        </div>
      </section>

      {/* <div className="features-serise d-inline-block w-100">
  <div className="container">
    <div className="d-flex align-items-center justify-content-between">
      <h2 className="main-hed01">Featured Series</h2>
      <a href="#" className="btn btn-asee d-none d-md-inline-block">
        More Match <i className="fas fa-angle-right" />
      </a>
    </div>
    <div className="coverages-slider owl-carousel owl-theme mt-4">
      <a href="#" className="items-ciu d-inline-block w-100">
        <div className="img-oudi">
          <img alt="sut" src="images/turm.webp" />
          <span className="btn btn-ofu"> T20 Cup </span>
        </div>
        <div className="cup-details">
          <h5>Cricket World Cup 2023 Logo: ICC</h5>
          <p>13 Oct 2023 - 02 Dec 2023</p>
        </div>
      </a>
      <a href="#" className="items-ciu d-inline-block w-100">
        <div className="img-oudi">
          <img alt="sut" src="images/tru2.png" />
          <span className="btn btn-ofu"> ODI </span>
        </div>
        <div className="cup-details">
          <h5>Aisa Cup 2023 Logo: ICC</h5>
          <p>13 Oct 2023 - 02 Dec 2023</p>
        </div>
      </a>
      <a href="#" className="items-ciu d-inline-block w-100">
        <div className="img-oudi">
          <img alt="sut" src="images/turm2.webp" />
          <span className="btn btn-ofu"> ODI </span>
        </div>
        <div className="cup-details">
          <h5>ENGLAND Men Tour 2023: ICC</h5>
          <p>13 Oct 2023 - 02 Dec 2023</p>
        </div>
      </a>
      <a href="#" className="items-ciu d-inline-block w-100">
        <div className="img-oudi">
          <img alt="sut" src="images/tru3.png" />
          <span className="btn btn-ofu"> ODI </span>
        </div>
        <div className="cup-details">
          <h5>European Cricket League T10</h5>
          <p>13 Oct 2023 - 02 Dec 2023</p>
        </div>
      </a>
      <a href="#" className="items-ciu d-inline-block w-100">
        <div className="img-oudi">
          <img alt="sut" src="images/tru4.jpg" />
          <span className="btn btn-ofu"> Domestic ODI </span>
        </div>
        <div className="cup-details">
          <h5>Road Safety World Series 2022 Live</h5>
          <p>13 Oct 2023 - 02 Dec 2023</p>
        </div>
      </a>
    </div>
    <a href="#" className="btn btn-asee d-table d-md-none mx-auto mt-4">
      More Match <i className="fas fa-angle-right" />
    </a>
  </div>
</div> */}
    </>
  );
};

export default Featuresearies;
