import React, { useContext } from 'react'
import { CMSContext } from './CMSContext'

const Terms = () => {
  const { CMSData } = useContext(CMSContext)

  const TermsConditions = CMSData?.find(page => page.slug === 'TermsConditions')

  const termsdescription = TermsConditions
    ? TermsConditions.description
    : 'Terms & Condition not found.'

  const termsHeading = TermsConditions
    ? TermsConditions.title
    : 'Terms & Condition not found.'

  // Function to strip HTML tags from a string
  const stripHtmlTags = html => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  return (
    <>
      <section className='banner-part sub-main-banner mb-4 float-start w-100'>
        <div className='baner-imghi'>
          <img
            src='https://oxentictemplates.in/templatemonster/cricket-new/images/sub-banner01.jpg'
            alt='sub-banner'
          />
        </div>
        <div className='sub-banner'>
          <div className='container'>
            <h1 className='text-center'>TERMS OF CONDITIONS</h1>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'>
                  <a href='#'>Home</a>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  TERMS OF USE
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className='container'>
        <h5 className='comon-heading m-0'>{termsHeading}</h5>
        <p className='mt-2'>{stripHtmlTags(termsdescription)}</p>
      </div>
    </>
  )
}

export default Terms
