import React, { createContext, useState, useEffect } from 'react'

export const CMSContext = createContext()

export const CMSProvider = ({ children }) => {
  const [CMSData, setCMSData] = useState(null)

  const baseUrl = process.env.REACT_APP_API_BASE_URL

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(`${baseUrl}cms-page`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setCMSData(result?.data)
      })
      .catch(error => console.log('error', error))
  }, [])
  return (
    <CMSContext.Provider value={{ CMSData, setCMSData }}>
      {children}
    </CMSContext.Provider>
  )
}
