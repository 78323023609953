import React, { useContext, useState } from 'react'
import { FiFacebook, FiInstagram } from 'react-icons/fi'
import { FaXTwitter } from 'react-icons/fa6'
import { Link, NavLink } from 'react-router-dom'
import { AiOutlineDownload } from 'react-icons/ai'
import { CategotyContext } from './CategotyContext'
import { SettingsContext } from './SettingsContext'
import { ToastContainer, toast } from 'react-toastify'

const Header = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const { categoryData } = useContext(CategotyContext)
  const { settingsData } = useContext(SettingsContext)
  const [email, setEmail] = useState('')

  const socialLinksMap = {}
  if (settingsData && settingsData.length > 0) {
    settingsData.forEach(item => {
      socialLinksMap[item.id] = item.value
    })
  }

  // ==========Subscribe Newsletter===============
  const Subscribe = e => {
    e.preventDefault()
    var formdata = new FormData()
    formdata.append('email', email)

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}user-subscribe`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          setEmail('')
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }

  return (
    <>
      <ToastContainer />
      <header className=' w-100'>
        <div className='top-new-heda  w-100'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-6 col-7'>
                {' '}
                <ul className='top-socli  mb-0 p-0'>
                  {settingsData && settingsData.length > 0 && (
                    <li className=''>
                      <NavLink
                        to={socialLinksMap[13]}
                        target='_blank'
                        className='btn'
                      >
                        {' '}
                        {/* Facebook */}
                        <FiFacebook />
                      </NavLink>
                      <NavLink
                        to={socialLinksMap[14]}
                        target='_blank'
                        className='btn'
                      >
                        {' '}
                        {/* Instagram */}
                        <FiInstagram />
                      </NavLink>
                      <NavLink
                        to={socialLinksMap[12]}
                        target='_blank'
                        className='btn'
                      >
                        {' '}
                        {/* Twitter */}
                        <FaXTwitter />
                      </NavLink>
                      <NavLink
                        to='https://news.google.com/publications/CAAqBwgKMMrQxwsw_uveAw?hl=en-IN&gl=IN&ceid=IN%3Aen'
                        target='_blank'
                        className='btn'
                      >
                        {' '}
                        <img
                          src='../images/news.png'
                          style={{ maxWidth: '20px' }}
                        />
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-5'>
                {' '}
                <div className='header_download_btn d-flex justify-content-end'>
                  <p className='mb-0 text-light app_download_btn_desktop'>
                    Download Our App Now
                    <button className='comon-btn'>
                      Download Now{' '}
                      <AiOutlineDownload style={{ fontSize: '20px' }} />
                    </button>
                  </p>
                  <button className='comon-btn app_download_btn_mobile'>
                    Download App{' '}
                    <AiOutlineDownload style={{ fontSize: '20px' }} />
                  </button>
                </div>
              </div>
              {/* <div className='col-lg-4 col-md-4 col-sm-12'>
                <form onSubmit={Subscribe} className='header_form'>
                  <div className='d-flex d_flex'>
                    <input
                      className='sub_input form-control'
                      type='email'
                      placeholder='your email address'
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    ></input>
                    <button className='comon-btn' type='submit'>
                      Subcribe
                    </button>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
        <nav className='navbar navbar-expand-lg navbar-light'>
          <div className='container'>
            <Link to='/' className='navbar-brand'>
              <img src='../images/mainlogo.png' alt='logo' className='logo' />
              <div>
                <h1>CricChamp</h1>
                <p> Cricket Updates, Analysis & Prediction</p>
              </div>
            </Link>
            <button
              class='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span class='navbar-toggler-icon'></span>
            </button>
            <div class='collapse navbar-collapse' id='navbarSupportedContent'>
              <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                <li className='nav-item'>
                  <Link to='/' className='nav-link'>
                    Home
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/live-match' className='nav-link'>
                    Live Match
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='https://news.google.com/publications/CAAqBwgKMMrQxwsw_uveAw?hl=en-IN&gl=IN&ceid=IN%3Aen'
                    className='nav-link'
                    target='_blank'
                  >
                    <img src='../images/news.png' />
                    Follow
                  </Link>
                </li>
                {/* {categoryData?.map((data, index) => (
                  <li className='nav-item' key={index}>
                    <Link to={`blogs/${data.name}`} className='nav-link'>
                      {data.name}
                    </Link>
                  </li>
                ))} */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
