import React, { useContext, useEffect, useState } from 'react'
import { FaAngleDoubleRight } from 'react-icons/fa'
import { NavLink, useParams } from 'react-router-dom'
import { BlogDataContext } from './BlogDataContext'
import dayjs from 'dayjs'
import { LiveBlogContext } from './LiveBlogContext'

const LiveMatch = () => {
  const [liveMatch, setLiveMatch] = useState([])
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  const imgurl = process.env.REACT_APP_API_IMG_URL
  const { LiveBlogData } = useContext(LiveBlogContext)

  // Function to strip HTML tags from a string
  const stripHtmlTags = html => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  // ==================page title==================
  const useDocumentTitle = title => {
    useEffect(() => {
      document.title = title
    }, [title])
  }
  useDocumentTitle(
    'Cricket live match blog | Commentary | Auction | Analysis - CricChamp'
  )

  return (
    <div>
      {/* <section className='banner-part sub-main-banner float-start w-100'>
        <div className='baner-imghi'>
          <img
            src='https://oxentictemplates.in/templatemonster/cricket-new/images/sub-banner01.jpg'
            alt='sub-banner'
          />
        </div>
        <div className='sub-banner'>
          <div className='container'>
            <h1 className='text-center'>Live Match</h1>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'>
                  <NavLink to='/'>Home</NavLink>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Live Match
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section> */}

      <div className='mt-3 mb-3 d-inline-block w-100 '>
        <div className='container category_data'>
          <div className='row mt-0 gy-5 g-lg-4'>
            <div className='col-lg-10'>
              {LiveBlogData && LiveBlogData.length > 0 && (
                <NavLink
                  to={`/live-blog-details/${LiveBlogData[0]?.slug}`}
                  className='comon-ret big-ret d-inline-block w-100 '
                >
                  <div className='imh-news position-relative'>
                    <img
                      alt='sty'
                      src={`${imgurl}${LiveBlogData[0]?.desc_image}`}
                    />
                  </div>
                  <div className='nes-text'>
                    <h5>{LiveBlogData[0]?.title}</h5>
                    <p className='truncate-text'>
                      {stripHtmlTags(LiveBlogData[0]?.short_description)}
                    </p>
                    <h6>
                      {' '}
                      <span>
                        {dayjs(LiveBlogData[0]?.created_at).format(
                          'MMM DD, YYYY'
                        )}
                      </span>{' '}
                      ||
                      <span> {LiveBlogData[0]?.name} </span>
                    </h6>
                  </div>
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>

      <section className='container'>
        <div className='row'>
          <div className='col-lg-8 col-xl-8 col-md-12'>
            <div className='recent-post-div mt-5'>
              <div className='recent-post-div-insiide'>
                {LiveBlogData?.slice(1).map((data, index) => (
                  <NavLink
                    to={`/live-blog-details/${data?.slug}`}
                    className='d-flex w-100  align-items-center'
                    key={index}
                  >
                    <figure>
                      <img
                        src={`${imgurl}${data?.desc_image}`}
                        alt={data.title}
                      />
                    </figure>
                    <div className=''>
                      <h5> {data.title}</h5>
                      <h6>
                        {' '}
                        <span>
                          {dayjs(data?.created_at).format('MMM DD, YYYY')}
                        </span>{' '}
                        ||
                        <span> {data?.name} </span>
                      </h6>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LiveMatch
