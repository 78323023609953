import React, { useContext } from 'react'
import { BlogDataContext } from './BlogDataContext'
import dayjs from 'dayjs'
import { NavLink } from 'react-router-dom'

const Latestnews = () => {
  const { blogeData } = useContext(BlogDataContext)
  const imgurl = process.env.REACT_APP_API_IMG_URL

  // Filter blogs with category_name as "TOP MOST"
  const LatestNews = blogeData?.data?.data?.filter(
    blog => blog.category_name === 'LATEST NEWS'
  )
  // Function to strip HTML tags from a string
  const stripHtmlTags = html => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }
  return (
    <>
      <div className='newsli-div d-inline-block w-100'>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between'>
            <h2 className='main-hed01 text-light'>Latest News</h2>
            {/* <a
              href='#'
              className='btn btn-asee d-none d-md-inline-block text-light'
            >
              More News <FaAngleDoubleRight style={{ fontSize: '20px' }} />
            </a> */}
          </div>
          <div className='row mt-0 gy-5 g-lg-4'>
            <div className='col-lg-6'>
              {LatestNews && LatestNews.length > 0 && (
                <NavLink
                  to={`/blog/${LatestNews[0]?.slug}`}
                  className='comon-ret big-ret d-inline-block w-100'
                >
                  <div className='imh-news position-relative'>
                    <img
                      alt='sty'
                      src={`${imgurl}${LatestNews[0]?.desc_image}`}
                    />
                  </div>
                  <div className='nes-text'>
                    <h5 className='truncate-text'>{LatestNews[0]?.title}</h5>
                    <p className='truncate-text'>
                      {stripHtmlTags(LatestNews[0]?.short_description)}
                    </p>
                    <h6>
                      <span>
                        {dayjs(LatestNews[0]?.created_at).format(
                          'MMM DD, YYYY'
                        )}
                      </span>{' '}
                      ||
                      <span> {LatestNews[0]?.name} </span>
                    </h6>
                  </div>
                </NavLink>
              )}
            </div>
            <div className='col-lg-6 news'>
              <div className='row row-cols-1 row-cols-sm-2 gy-5 g-lg-4'>
                {LatestNews?.slice(1, 4).map((data, index) => (
                  <div className='col-lg-6' key={index}>
                    <NavLink
                      to={`/blog/${data?.slug}`}
                      className='comon-ret d-inline-block w-100'
                    >
                      <div className='imh-news'>
                        <img alt='sty' src={`${imgurl}${data?.desc_image}`} />
                      </div>
                      <div className='nes-text'>
                        <h5>{data?.title}</h5>
                        <h6>
                          <span>
                            {dayjs(data?.created_at).format('MMM DD, YYYY')}
                          </span>{' '}
                          <span>{data?.name} </span>
                        </h6>
                      </div>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <a href='#' className='btn btn-asee d-table d-md-none mx-auto mt-4'>
            More News <i className='fas fa-angle-right' />
          </a>
        </div>
      </div>
    </>
  )
}

export default Latestnews
