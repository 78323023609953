import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Loader from './Loader'
import { BlogDataContext } from './BlogDataContext'
import dayjs from 'dayjs'

const TopArticles = ({ deviceType }) => {
  const { blogeData } = useContext(BlogDataContext)
  const imgurl = process.env.REACT_APP_API_IMG_URL
  const topArticles = blogeData?.data?.data?.filter(
    blog => blog.category_name === 'TOP ARTICLES'
  )

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }

  return (
    <>
      <section className='ptb'>
        <div className='match-cover01'>
          <div className='container '>
            <div className='cover'>
              <h2 className='main-hed01'>Top Articles</h2>
              {/* <NavLink
                to={`/blogs/${data.name}`}
                className='btn btn-asee d-none d-md-inline-block'
              >
                More Articles{' '}
                <FaAngleDoubleRight style={{ fontSize: '20px' }} />
              </NavLink> */}
            </div>

            {topArticles ? ( // Conditionally render Carousel if topArticles is truthy
              <Carousel
                responsive={responsive}
                swipeable={false}
                draggable={false}
                ssr={true}
                infinite={true}
                autoPlay={deviceType !== 'mobile' ? true : false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition='all .5s'
                transitionDuration={1500}
                containerClass='carousel-container'
                removeArrowOnDeviceType={['tablet', 'mobile']}
                deviceType={deviceType}
                dotListClass='custom-dot-list-style'
                itemClass='carousel-item-padding-40-px'
              >
                {topArticles.map((data, index) => (
                  <div key={index} className='slider'>
                    {' '}
                    {/* Fixed key attribute */}
                    <NavLink
                      to={`/blog/${data?.slug}`}
                      className='play-button comon-vedo-divu d-inline-block w-100 position-relative'
                    >
                      <div className='vio-imghj'>
                        <img
                          alt={data?.title}
                          src={`${imgurl}${data?.desc_image}`}
                        />
                      </div>
                      <p className='trecty'>
                        <h5>{data?.title}</h5>
                        <span>
                          {dayjs(data?.created_at).format('MMM DD, YYYY')}
                        </span>{' '}
                        ||
                        <span> {data?.name} </span>
                      </p>
                    </NavLink>
                  </div>
                ))}
              </Carousel>
            ) : (
              <Loader /> // Render a loader or alternative content while data is loading
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default TopArticles
