import React from 'react'

const Matchhighlight = () => {
  return (
    <>
      <div className='helights d-inline-block w-100'>
        <div className='container'>
          <h2 className='main-hed01 text-light'>Match highlight</h2>
          <div className='row mt-4'>
            <div className='col-lg-8'>
              <div className='big-viuy d-inline-block w-100'>
                <video id='videoplaylist' preload='auto' tabIndex={0} controls>
                  <source src='https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4' />
                </video>
              </div>
            </div>
            <div className='col-lg-4'>
              <ul id='playlist'>
                <li className='active'>
                  <a
                    href='https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                    className='voi-paly'
                  >
                    <div className='imgh-voi'>
                      <img alt='syiu' src='images/gfg.jpg' />
                    </div>
                    <div className='img-detau'>
                      <h5>
                        World Cup run home: How each team can qualify for the...
                      </h5>
                      <p>Mond 3 Oct</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href='https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4'
                    className='voi-paly'
                  >
                    <div className='imgh-voi'>
                      <img alt='syiu' src='images/-1x-1.jpg' />
                    </div>
                    <div className='img-detau'>
                      <h5>Old-fashioned method fuels de Kock's ...</h5>
                      <p>Mond 3 Oct</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href='https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                    className='voi-paly'
                  >
                    <div className='imgh-voi'>
                      <img alt='syiu' src='images/3364.webp' />
                    </div>
                    <div className='img-detau'>
                      <h5>India look to seal semi-final spot against ...</h5>
                      <p>Mond 3 Oct</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href='https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                    className='voi-paly'
                  >
                    <div className='imgh-voi'>
                      <img alt='syiu' src='images/bhjb.jpg' />
                    </div>
                    <div className='img-detau'>
                      <h5>
                        World Cup run home: How each team can qualify for the...
                      </h5>
                      <p>Mond 3 Oct</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Matchhighlight
