import React, { createContext, useState, useEffect } from 'react'

export const CategotyContext = createContext()

export const CategotyProvider = ({ children }) => {
  const [categoryData, setCategoryData] = useState(null)

  const baseUrl = process.env.REACT_APP_API_BASE_URL

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(`${baseUrl}category-list`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setCategoryData(result?.data)
      })
      .catch(error => console.log('error', error))
  }, [])
  return (
    <CategotyContext.Provider value={{ categoryData, setCategoryData }}>
      {children}
    </CategotyContext.Provider>
  )
}
