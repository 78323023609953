import React, { useContext } from 'react'
import { CMSContext } from './CMSContext'
import { FaCalendarAlt, FaEye, FaFlagCheckered } from 'react-icons/fa'

const About = () => {
  const { CMSData } = useContext(CMSContext)

  const aboutusContent = CMSData?.find(page => page.slug === 'AboutUs')

  const aboutusdescription = aboutusContent
    ? aboutusContent.description
    : 'About us not found.'

  const aboutusHeading = aboutusContent
    ? aboutusContent.title
    : 'About us not found.'

  // Function to strip HTML tags from a string
  const stripHtmlTags = html => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  return (
    <>
      <section class='banner-part sub-main-banner float-start w-100'>
        <div class='baner-imghi'>
          <img
            src='https://oxentictemplates.in/templatemonster/cricket-new/images/sub-banner01.jpg'
            alt='sub-banner'
          />
        </div>

        <div class='sub-banner'>
          <div class='container'>
            <h1 class='text-center'> {aboutusHeading}</h1>
            <nav aria-label='breadcrumb'>
              <ol class='breadcrumb justify-content-center'>
                <li class='breadcrumb-item'>
                  <a href='#'>Home</a>
                </li>
                <li class='breadcrumb-item active' aria-current='page'>
                  {aboutusHeading}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className='about-page-main comon-sub-page-main d-inline-block w-100'>
        <div className='about-club-top'>
          <div className='container'>
            <div className='row row-cols-1 row-cols-lg-2 g-lg-5'>
              <div className='col position-relative'>
                <figure className='big-img'>
                  <img
                    src='https://oxentictemplates.in/templatemonster/cricket-new/images/GettyImages-1764843770.webp'
                    alt='pic'
                  />
                </figure>
                <figure className='small-img'>
                  <img
                    src='https://oxentictemplates.in/templatemonster/cricket-new/images/ghj.jpeg'
                    alt='pic2'
                  />
                </figure>
              </div>
              <div className='col'>
                <h5 className='samll-sub mb-1 mt-0'> Our Story </h5>
                <h2 className='comon-heading m-0'> {aboutusHeading} </h2>
                <p className='mt-3'>{stripHtmlTags(aboutusdescription)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='our-history-div d-inline-block w-100 mt-5'>
        <div className='container'>
          <ul className='nav nav-tabs' id='myTab' role='tablist'>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link active'
                id='home-tab'
                data-bs-toggle='tab'
                data-bs-target='#home'
                type='button'
                role='tab'
                aria-controls='home'
                aria-selected='true'
              >
                {' '}
                <FaCalendarAlt />
                Our History{' '}
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                id='profile-tab'
                data-bs-toggle='tab'
                data-bs-target='#profile'
                type='button'
                role='tab'
                aria-controls='profile'
                aria-selected='false'
              >
                {' '}
                <FaFlagCheckered /> Our Mission{' '}
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                id='contact-tab'
                data-bs-toggle='tab'
                data-bs-target='#contact'
                type='button'
                role='tab'
                aria-controls='contact'
                aria-selected='false'
              >
                <FaEye />
                Our Vision{' '}
              </button>
            </li>
          </ul>
        </div>
        <div className='tab-content mna-bg' id='myTabContent'>
          <div className='tab-pane fade show active' id='home' role='tabpanel'>
            <div className='container'>
              <div className='tab-content' id='pills-tabContent'>
                <div
                  className='tab-pane fade show active'
                  id='yd-hom1'
                  role='tabpanel'
                >
                  <div className='comon-fild-ads1 d-md-flex align-items-lg-center'>
                    <figure>
                      <img
                        src='https://oxentictemplates.in/templatemonster/cricket-new/images/63efef8febea0.jpg'
                        alt='bg'
                      />
                    </figure>
                    <div className='left-history mt-3 mt-md-0'>
                      <h2>It is a long established fact that a reader </h2>
                      <p>
                        {' '}
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='yd-hom2' role='tabpanel'>
                  <div className='comon-fild-ads1 d-md-flex align-items-center'>
                    <figure>
                      <img
                        src='https://images.unsplash.com/photo-1571801366823-ae2ea851a3de?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fGNyaWNrZXQlMjBzcG9ydHxlbnwwfHwwfHx8MA%3D%3D'
                        alt='bg'
                      />
                    </figure>
                    <div className='left-history mt-3 mt-md-0'>
                      <h2>It is a long established fact that a reader </h2>
                      <p>
                        {' '}
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='yd-hom3' role='tabpanel'>
                  <div className='comon-fild-ads1 d-md-flex align-items-center'>
                    <figure>
                      <img
                        src='https://images.unsplash.com/photo-1635127725453-5f6ad32cbf92?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTl8fGNyaWNrZXQlMjBzcG9ydHxlbnwwfHwwfHx8MA%3D%3D'
                        alt='bg'
                      />
                    </figure>
                    <div className='left-history mt-3 mt-md-0'>
                      <h2>It is a long established fact that a reader </h2>
                      <p>
                        {' '}
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='yd-hom4' role='tabpanel'>
                  <div className='comon-fild-ads1 d-md-flex align-items-center'>
                    <figure>
                      <img
                        src='https://images.unsplash.com/photo-1635127725453-5f6ad32cbf92?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTl8fGNyaWNrZXQlMjBzcG9ydHxlbnwwfHwwfHx8MA%3D%3D'
                        alt='bg'
                      />
                    </figure>
                    <div className='left-history mt-3 mt-md-0'>
                      <h2>It is a long established fact that a reader </h2>
                      <p>
                        {' '}
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade' id='yd-hom5' role='tabpanel'>
                  <div className='comon-fild-ads1 d-md-flex align-items-center'>
                    <figure>
                      <img
                        src='https://images.unsplash.com/photo-1599982973590-2de8279c6114?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODB8fGNyaWNrZXQlMjBzcG9ydHxlbnwwfHwwfHx8MA%3D%3D'
                        alt='bg'
                      />
                    </figure>
                    <div className='left-history'>
                      <h2>It is a long established fact that a reader </h2>
                      <p>
                        {' '}
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-pane fade' id='profile' role='tabpanel'>
            <div className='container'>
              <div className='comon-fild-ads1 d-md-flex align-items-center'>
                <figure>
                  <img
                    src='https://images.unsplash.com/photo-1624526267942-ab0ff8a3e972?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDR8fGNyaWNrZXQlMjBzcG9ydHxlbnwwfHwwfHx8MA%3D%3D'
                    alt='bg'
                  />
                </figure>
                <div className='left-history mt-3 mt-md-0'>
                  <h2>It is a long established fact that a reader </h2>
                  <p>
                    {' '}
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-pane fade' id='contact' role='tabpanel'>
            <div className='container'>
              <div className='comon-fild-ads1 d-md-flex align-items-center'>
                <figure>
                  <img
                    src='https://images.unsplash.com/photo-1565787154274-c8d076ad34e7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGNyaWNrZXQlMjBzcG9ydHxlbnwwfHwwfHx8MA%3D%3D'
                    alt='bg'
                  />
                </figure>
                <div className='left-history mt-3 mt-md-0'>
                  <h2>It is a long established fact that a reader </h2>
                  <p>
                    {' '}
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
