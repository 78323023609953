import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'bootstrap';

const Hero = () => {
  useEffect(() => {
    const initializeCarousel = () => {
      const carouselElement = document.getElementById('carouselExampleFade');
      if (carouselElement) {
        // Initialize the Bootstrap Carousel
        const carousel = new Carousel(carouselElement, {
          interval: 2000, // Set the sliding interval (in milliseconds)
          // Other options you might want to configure
        });
      }
    };

    initializeCarousel(); // Call the function to initialize the carousel

    return () => {
      // Clean up or dispose of the carousel when the component unmounts
      // This is optional if you want to dispose of the carousel
      // You can remove this part if not needed
      const carouselElement = document.getElementById('carouselExampleFade');
      if (carouselElement) {
        const carousel = Carousel.getInstance(carouselElement);
        if (carousel) {
          carousel.dispose();
        }
      }
    };
  }, []);
  return (
   <>
    <section className=" w-100 banner-part">
  <div className="slider-banner">
    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to={1} aria-label="Slide 2" />
      </div>
      <div className="carousel-inner">
        <div className="carousel-item add-fiu active">
          <img src="images/banh1.jpg" alt="images not found" />
          <div className="container position-relative">
            <div className="cover">
              <div className="row">
                <div className="col-lg-7">
                  <div className="header-content">
                    <h1 className="fadeInDown animated">
                      Post Match Shenanigans ft. Shubman Gill | India vs Bangladesh
                    </h1>
                    <p className="mt-3"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                    </p>
                    <a href="#" className="btn btn-comon-btn">
                      <i className="fas fa-play-circle" /> watch streaming
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item add-fiu">
          <img src="images/ban012.jpg" alt="images not found" />
          <div className="container position-relative">
            <div className="cover">
              <div className="row">
                <div className="col-lg-7">
                  <div className="header-content">
                    <h1 className="fadeInDown animated">
                      Post Match Shenanigans ft. Shubman Gill | India vs Bangladesh
                    </h1>
                    <p className="mt-3"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                    </p>
                    <a href="#" className="btn btn-comon-btn">
                      <i className="fas fa-play-circle" /> Watch highlight
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* <button type="button" className="btn btn-member" data-bs-toggle="modal" data-bs-target="#memberModal"> Become A Member </button> */}
</section>

   </>
  )
}

export default Hero