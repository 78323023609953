import React from 'react'
import { FiFacebook, FiInstagram } from 'react-icons/fi'
import { FaXTwitter } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <footer class='float-start w-100'>
        <div class='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-10 col-sm-12 m-auto'>
              <div className='logo_footer'>
                <img
                  src='../images/mainlogo.png'
                  alt='logo'
                  className=' img-fluied'
                />
              </div>
              <div className='footer_links'>
                <Link to='/about'>ABOUT US</Link>
                <a href='#'>CAREERS</a>
                <Link to='/contact'>CONTACT US</Link>
                <Link to='/terms'>TERMS OF CONDITION</Link>
                <Link to='/policy'>POLICIES</Link>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-div1 mt-4'>
          <div className='container'>
            <div className='row row-cols-1 row-cols-md-2 text-center text-md-start gy-4 gy-lg-0 justify-content-center justify-content-md-between'>
              <div className='col'>
                <p className='text-white'>
                  Copyright © 2023.All Rights Reserved
                </p>
              </div>
              {/* <div className="col d-grid justify-content-end">
              <ul className="top-socli d-flex align-items-center">
                <li>Follow Us</li>
                <li className="ms-3">
                  <a href="#" className="btn">
                    {" "}
                   <FiFacebook/>
                  </a>
                  <a href="#" className="btn">
                    {" "}
                   <FiInstagram/>
                  </a>
                  <a href="#" className="btn">
                    {" "}
                   <FaXTwitter/>
                  </a>
                </li>
              </ul>
            </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
